import React from "react";
import PropTypes from "prop-types";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/Layout";
import PageTitle from "../components/PageTitle";
import { makeStyles } from "@material-ui/styles";
import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Fade from "react-reveal/Fade";
import { Github } from "@icons-pack/react-simple-icons";
import Cloud from "@material-ui/icons/Cloud";
import Typography from "@material-ui/core/Typography";
import Chip from "@material-ui/core/Chip";

const btn = theme => ({
  marginTop: theme.spacing(1),
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),

  alignSelf: "center",
  borderRadius: 50,
  padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`
});

const useStyles = makeStyles(theme => ({
  img: {
    boxShadow: theme.shadows[8],
    marginTop: theme.spacing(-8),
    borderRadius: theme.borderRadius
  },
  btn: btn(theme),
  sourceBtn: {
    ...btn(theme),
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      color: "white"
    }
  },
  btns: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(4),
    display: "flex",
    justifyContent: "flex-start",
    flexWrap: "wrap"
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  container: {
    display: "flex",
    flexDirection: "column"
  },
  chips: {
    marginTop: theme.spacing(4)
  },
  chip: {
    margin: theme.spacing(1)
  },
  paragraph: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    lineHeight: 1.75
  },
  wrapper: {
    maxWidth: 800,
    margin: "0 auto"
  }
}));

const ProjectTemplate = ({ data }) => {
  const { contentfulProject } = data;
  const classes = useStyles();
  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Typography className={classes.paragraph}>{children}</Typography>
      )
    },
    renderText: text => text.replace("!", "?")
  };
  const desc = documentToReactComponents(contentfulProject.desc.json, options);

  return (
    <Layout>
      <PageTitle bottomPadding>{contentfulProject.title}</PageTitle>
      <Container className={classes.container}>
        <Fade up duration={300}>
          <a
            href={contentfulProject.demoLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Img
              fluid={contentfulProject.thumbnail.fluid}
              className={classes.img}
            />
          </a>
        </Fade>
        <div className={classes.wrapper}>
          <div className={classes.chips}>
            {contentfulProject.techs.map((tech, index) => (
              <Chip className={classes.chip} label={tech.name} key={index} />
            ))}
          </div>

          <div className={classes.btns}>
            <Button
              className={classes.btn}
              color="primary"
              variant="contained"
              component="a"
              href={contentfulProject.demoLink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Cloud className={classes.icon} />
              Live Demo
            </Button>
            {contentfulProject.sourceLink !== null ? (
              <Button
                className={classes.sourceBtn}
                color="primary"
                variant="outlined"
                component="a"
                href={contentfulProject.sourceLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Github className={classes.icon} />
                Source Code
              </Button>
            ) : (
              ""
            )}
          </div>
          <div style={{ marginTop: 20 }}>{desc}</div>
        </div>
      </Container>
    </Layout>
  );
};

export default ProjectTemplate;

export const query = graphql`
  query ProjectTemplate($id: String!) {
    contentfulProject(id: { eq: $id }) {
      title
      desc {
        json
      }
      demoLink
      sourceLink
      techs {
        name
      }
      thumbnail {
        fluid(maxWidth: 1216) {
          base64
          aspectRatio
          src
          srcSet
        }
      }
    }
  }
`;

ProjectTemplate.propTypes = {
  data: PropTypes.object.isRequired
};
